import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Container, NavLink, Grid, Stack, Text } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'

import navLinkTheme from 'assets/themes/navLinkTheme'
import useFooterMain from './hooks/useFooterMain'
import styles from './styles.module.scss'

const FooterMain = () => {
    const theme = useMantineTheme()
    const location = useLocation()
    const { countryCode = null, pid = null } = useSelector(
        (state) => state.auth.user ?? {}
    )
    const exceptions = [
        '652eebc6103c11b4618e7329',
        '652eeb9d103c11b4618e7323',
        '65675792abf8a936f3ac9531',
        '652eec30103c11b4618e7339',
        '656757a4abf8a936f3ac9532',
    ]
    const { footer } = navLinkTheme(theme)
    const {
        legalLinks,
        shortcutsLinks,
        projectAuthNumber,
        instanceCountryCode,
    } = useFooterMain()
    function isLinkAvailable(value) {
        if (countryCode === 'AR') {
            if (value.slug === 'sitios-de-interes') {
                if (exceptions.includes(pid)) {
                    return value
                }
            } else {
                return value
            }
        } else {
            return value
        }
    }
    const userShortcutsLinks = shortcutsLinks
        ? shortcutsLinks.filter(isLinkAvailable)
        : []
    return (
        <div className={styles.container}>
            <footer>
                <Container fluid className={styles.smFooter}>
                    <Grid>
                        <Grid.Col span={3}>
                            <Stack spacing='sm'>
                                {legalLinks && (
                                    <Text
                                        sx={(theme) => ({
                                            color: theme.colors.darkGrey[0],
                                            fontWeight: 'bold',
                                        })}
                                    >
                                        Nuestra compañía
                                    </Text>
                                )}
                                {legalLinks &&
                                    legalLinks.map((link) => (
                                        <NavLink
                                            active={
                                                location.pathname === link.slug
                                            }
                                            component={Link}
                                            key={uuidv4()}
                                            label={link.label}
                                            styles={footer}
                                            to={`/${link.slug}`}
                                        />
                                    ))}
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Stack spacing='sm'>
                                {userShortcutsLinks &&
                                    userShortcutsLinks.length > 0 && (
                                        <Text
                                            sx={(theme) => ({
                                                color: theme.colors.darkGrey[0],
                                                fontWeight: 'bold',
                                            })}
                                        >
                                            Mapa del sitio
                                        </Text>
                                    )}
                                {userShortcutsLinks &&
                                    userShortcutsLinks.map((link) => (
                                        <NavLink
                                            active={
                                                location.pathname === link.slug
                                            }
                                            component={Link}
                                            key={uuidv4()}
                                            label={link.label}
                                            styles={footer}
                                            to={`/${link.slug}`}
                                        />
                                    ))}
                            </Stack>
                        </Grid.Col>
                        <Grid.Col
                            span={6}
                            sx={(theme) => ({
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end',
                                color: theme.colors.purple[0],
                            })}
                        >
                            <Text
                                sx={(theme) => ({
                                    color: theme.colors.darkGrey[0],
                                    fontSize: theme.fontSizes.md,
                                })}
                            >
                                {instanceCountryCode === 'mx'
                                    ? `Número de aviso SSA: ${projectAuthNumber}`
                                    : `Autorización No. SSA: ${projectAuthNumber}`}
                            </Text>
                            <Text
                                sx={(theme) => ({
                                    color: theme.colors.purple[0],
                                })}
                            >
                                © 2023 Bristol-Myers Squibb Company
                            </Text>
                        </Grid.Col>
                    </Grid>
                </Container>
                <Container className={styles.xsFooter}>
                    <Grid sx={{ width: '100%' }}>
                        <Grid.Col span={12}>
                            <Stack spacing='sm'>
                                {legalLinks &&
                                    legalLinks.map((link) => (
                                        <NavLink
                                            active={
                                                location.pathname === link.slug
                                            }
                                            component={Link}
                                            key={uuidv4()}
                                            label={link.label}
                                            styles={footer}
                                            to={link.slug}
                                        />
                                    ))}
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Stack spacing='sm'>
                                {shortcutsLinks &&
                                    shortcutsLinks.map((link) => (
                                        <NavLink
                                            active={
                                                location.pathname === link.slug
                                            }
                                            component={Link}
                                            key={uuidv4()}
                                            label={link.label}
                                            styles={footer}
                                            to={`/${link.slug}`}
                                        />
                                    ))}
                            </Stack>
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                            sx={(theme) => ({
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: theme.colors.purple[0],
                            })}
                        >
                            <Text
                                sx={(theme) => ({
                                    color: theme.colors.darkGrey[0],
                                    fontSize: theme.fontSizes.md,
                                })}
                            >
                                {instanceCountryCode === 'mx'
                                    ? `Número de aviso SSA: ${projectAuthNumber}`
                                    : `Autorización No. SSA: ${projectAuthNumber}`}
                            </Text>
                            <Text
                                sx={(theme) => ({
                                    color: theme.colors.purple[0],
                                })}
                            >
                                © 2023 Bristol-Myers Squibb Company
                            </Text>
                        </Grid.Col>
                    </Grid>
                </Container>
            </footer>
        </div>
    )
}

export default FooterMain
