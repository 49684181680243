import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useDisclosure } from '@mantine/hooks'
import { Burger, Container, Drawer, NavLink } from '@mantine/core'

import { navLinkMainTheme } from '../../../assets/themes/links'
import { useNavigationMain } from './hooks/useNavigationMain'
import styles from './styles.module.scss'
import SettingsMenu from '../SettingsMenu/SettingsMenu'

const NavigationMain = () => {
    const location = useLocation()
    const { countryCode, pid } = useSelector((state) => state.auth.user)
    const exceptions = [
        '652eebc6103c11b4618e7329',
        '652eeb9d103c11b4618e7323',
        '65675792abf8a936f3ac9531',
        '652eec30103c11b4618e7339',
        '656757a4abf8a936f3ac9532',
    ]
    const { links } = useNavigationMain()
    function isLinkAvailable(value) {
        if (countryCode === 'AR') {
            if (value.slug === 'sitios-de-interes') {
                if (exceptions.includes(pid)) {
                    return value
                }
            } else {
                return value
            }
        } else {
            return value
        }
    }
    const userLinks = links.filter(isLinkAvailable)
    const [drawerOpened, { toggle: drawerToggle }] = useDisclosure(false)
    return (
        <div className={styles.container}>
            <nav>
                <Drawer
                    onClose={drawerToggle}
                    opened={drawerOpened}
                    overlayProps={{ opacity: 0.5, blur: 4 }}
                    position='left'
                    title='Authentication'
                    size='xs'
                >
                    {/* Drawer content */}
                </Drawer>
                <Container fluid className={styles.sm}>
                    <Burger onClick={drawerToggle} size='md' />
                </Container>
                <Container fluid className={styles.md}>
                    <ul>
                        {userLinks &&
                            userLinks.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <NavLink
                                            label={item.label}
                                            component={Link}
                                            to={`/${item.slug}`}
                                            active={
                                                location.pathname ===
                                                `/${item.slug}`
                                            }
                                            styles={navLinkMainTheme}
                                        />
                                    </li>
                                )
                            })}
                    </ul>
                    <ul>
                        <li>
                            <NavLink
                                label={'Mi perfil'}
                                component={Link}
                                to={'/mi-perfil'}
                                active={location.pathname === '/mi-perfil'}
                                styles={navLinkMainTheme}
                            />
                        </li>
                        <li>
                            <SettingsMenu />
                        </li>
                    </ul>
                </Container>
            </nav>
        </div>
    )
}

export default NavigationMain
