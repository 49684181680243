import React from 'react'
import {
    BackgroundImage,
    Container,
    Divider,
    Flex,
    Grid,
    Text,
    Title,
    useMantineTheme,
} from '@mantine/core'
import { IconMail, IconMicroscope, IconPhone } from '@tabler/icons-react'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import useMyMonitor from './hooks/useMyMonitor'
import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'
const MyMonitor = () => {
    const theme = useMantineTheme()

    const { instance, themes, pageData } = useScreen()
    /* const { breadcrumbsData, instance, pageData, services, themes } =
        useAboutTheProgram() */
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data, posts } = pageData
        return (
            <div className={styles.layout}>
                <HeaderMain />
                <NavigationMain />
                <main>
                    <HeaderPage
                        title={data.title}
                        summary={data.summary}
                        theme='themeAlmond'
                    />
                    <BreadcrumbsMain />
                    <Flex sx={{ width: '100%' }}>
                        <Container className={styles.container}>
                            <Grid gutter='4x'>
                                {posts.map((guide) => (
                                    <Grid.Col lg={3} md={4} sm={6} xs={12}>
                                        <Title c='darkGrey.0' order={4}>
                                            {guide.data.name}
                                        </Title>

                                        <Title c='darkGrey.3' order={6}>
                                            {guide.data.title}
                                        </Title>

                                        <Divider
                                            mt='2xs'
                                            size='xs'
                                            sx={(theme) => ({
                                                borderTopColor:
                                                    theme.colors.purple[0],
                                            })}
                                        />
                                        <Flex
                                            justify='flex-end'
                                            direction='column'
                                            align='flex-start'
                                            gap='.5rem'
                                            pt='.5rem'
                                        >
                                            <Flex align='middle' gap='.25rem'>
                                                <IconMicroscope
                                                    size={theme.spacing.lg}
                                                    stroke={2}
                                                    color={
                                                        theme.colors.purple[0]
                                                    }
                                                />
                                                <Text fz='sm' c='darkGrey.0'>
                                                    {
                                                        guide.data
                                                            .professionalArea
                                                    }
                                                </Text>
                                            </Flex>
                                            <Flex align='middle' gap='.25rem'>
                                                <IconPhone
                                                    size={theme.spacing.lg}
                                                    stroke={2}
                                                    color={
                                                        theme.colors.purple[0]
                                                    }
                                                />
                                                <Text fz='sm' c='darkGrey.0'>
                                                    {guide.data.contact.mobile}
                                                </Text>
                                            </Flex>
                                            <Flex align='middle' gap='.25rem'>
                                                <IconMail
                                                    size={theme.spacing.lg}
                                                    stroke={2}
                                                    color={
                                                        theme.colors.purple[0]
                                                    }
                                                />
                                                <Text fz='sm' c='darkGrey.0'>
                                                    {guide.data.contact.email}
                                                </Text>{' '}
                                            </Flex>
                                        </Flex>
                                        <Flex justify='flex-end'></Flex>
                                    </Grid.Col>
                                ))}
                            </Grid>
                            {/* <Grid gutter='2x'>
                                <Grid.Col md={4} sm={12}>
                                    <BackgroundImage
                                        src={data.media.coverImage.src}
                                        sx={{
                                            aspectRatio: '5/4',
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col md={4} sm={12}>
                                    <Title c='darkGrey.0' order={2}>
                                        Diana King
                                    </Title>
                                    <Divider
                                        mt='xs'
                                        size='sm'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.purple[0],
                                        })}
                                    />
                                    <Text fz='lg' mt='xs' c='darkGrey.0'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat.
                                        Proin sed aliquet odio. Maecenas
                                        vestibulum elit et lacinia mattis.
                                    </Text>
                                </Grid.Col>
                                <Grid.Col md={4} sm={12}>
                                    <Title c='darkGrey.0' order={2}>
                                        Información de contacto
                                    </Title>
                                    <Divider
                                        mt='xs'
                                        size='sm'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.purple[0],
                                        })}
                                    />
                                    <Text fz='lg' mt='xs' c='darkGrey.0'>
                                        País: Mexico
                                    </Text>
                                    <Text fz='lg' mt='md' c='darkGrey.0'>
                                        Teléfono: (300) 555 4433
                                    </Text>
                                    <Text fz='lg' mt='md' c='darkGrey.0'>
                                        Correo Electrónico: diana.king@bms.com
                                    </Text>
                                </Grid.Col>
                            </Grid> */}
                        </Container>
                    </Flex>
                </main>
                <FooterMain />
            </div>
        )
    }
}

export default MyMonitor
