import React from 'react'
import { usePrivatePage } from './hooks/usePrivatePage'
import MyMonitor from 'components/UI/Widgets/MyMonitor/MyMonitor'

export const PrivatePage = () => {
    const { template, isLogged, instanceCountryCode } = usePrivatePage()
    if (isLogged) {
        return (
            <React.Fragment>
                {template}
                {/* {instanceCountryCode === 'mx' && <MyMonitor />} */}
            </React.Fragment>
        )
    }
}

export default PrivatePage
