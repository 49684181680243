import { useMantineTheme, Alert, Anchor, Container } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'

import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import TextFeatureBlock from 'components/UI/Blocks/TextFeatureBlock/TextFeatureBlock'

import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'

const SitesOfInterest = () => {
    const theme = useMantineTheme()

    const { instance, themes, pageData, countryCode } = useScreen()
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeOlive'
                        />
                        <BreadcrumbsMain />
                        <Container size='1280px' px={0} py='2x'>
                            <Alert
                                icon={
                                    <IconAlertCircle
                                        size={theme.fontSizes['4x']}
                                        color={theme.colors.white[0]}
                                    />
                                }
                                title='Aviso de privacidad!'
                                sx={{
                                    backgroundColor: theme.colors.danger[4],
                                    borderRadius: theme.spacing.xs,
                                    color: theme.colors.white[0],
                                    '& .mantine-Alert-message': {
                                        color: theme.colors.white[0],
                                    },
                                    '& .mantine-Alert-closeButton': {
                                        color: theme.colors.white[0],
                                    },
                                }}
                            >
                                Estos links llevan a páginas externas a Bristol
                                Myers Squibb, la empresa no se hace responsable
                                por el contenido de las mismas ni por las
                                prácticas de privacidad ni el tratamiento de los
                                datos personales.
                            </Alert>
                            {countryCode === 'ar' &&
                                pageData.posts &&
                                pageData.posts.map((site) => (
                                    <div
                                        key={site._id}
                                        className={styles.argentinaDisplay}
                                    >
                                        <div className={styles.imageContainer}>
                                            <img
                                                width='100%'
                                                height='100%'
                                                src={
                                                    site.data.media.coverImage
                                                        .src
                                                }
                                                alt='Vivir con cancer'
                                            />
                                        </div>
                                        <div className={styles.infoContainer}>
                                            <div>
                                                <div className={styles.title}>
                                                    {site.data.title}
                                                </div>
                                                <div className={styles.info}>
                                                    {site.data.description}
                                                </div>
                                            </div>
                                            <div className={styles.button}>
                                                <Anchor
                                                    href={
                                                        site.config.navigation
                                                            .slug
                                                    }
                                                    target='_blank'
                                                >
                                                    Ver nota completa
                                                </Anchor>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {countryCode !== 'ar' && pageData.posts && (
                                <>
                                    <CardsContainer
                                        aspectRatio='16/9'
                                        cols={4}
                                        component={<TextFeatureBlock />}
                                        items={pageData.posts}
                                    />
                                </>
                            )}
                            {pageData.posts.length === 0 && (
                                <div className={styles.noContent}>
                                    Actualmente no existen publicaciones
                                    relacionadas con su indicación terapeutica o
                                    medicamento.
                                </div>
                            )}
                        </Container>
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default SitesOfInterest
